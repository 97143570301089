import { get, isNil, uniq } from 'lodash-es'

import { observer } from 'mobx-react'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Image from 'next/image'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import * as Sentry from '@sentry/nextjs'

import { useQuery } from '@tanstack/react-query'

import { getCookies, useStores } from '@utils'
import { localStorageWithTTL } from '@utils/localStorage'

import { CompetitionCategorySlugEnum, QueryClientKeysEnum } from '@enums'

import {
  ApplicationConfigService,
  ConversationDTO,
  ConversationsService,
  PromotionsService
} from '@elitecompetitions/client-api'

import {
  registerMessageNotificationListener,
  registerPromotionListener
} from '@api/Messages'
import { storeUserTelemetry } from '@api/Users'

import { getLocalAssetFromCDN, numericWithComma } from '@helpers'

import { Container, Stack } from '@mui/material'

import { Typography } from '@lib/ui'

const Basket = dynamic(() => import('@features/basket/components/Basket'), {
  ssr: false
})

import { CloseIcon, HamburgerIcon, CartIcon, WalletIcon } from '../../icons'

import BannerPromotion from '../BannerPromotion'
import WalletModal from '../WalletModal'

import {
  StyledHeader,
  StyledMobileLogo,
  StyledMainMenu,
  StyledMainMenuList,
  StyledNavbar,
  StyledNavbarButton,
  StyledDesktopLogo,
  StyledButton,
  StyledScrollContainer,
  StyledMainMenuButtonSection,
  StyledBadge,
  StyledVillaItem,
  StyledWorkWithUsItem
} from './Navbar.styled'
import { type IMessageData, type IUserChatData } from './Navbar.types'

const Navbar = observer(function Navbar() {
  const { cartStore, authStore, notificationStore, basketWidgetStore } =
    useStores()

  const { isOpen: isOpenBasketWidget = false } = basketWidgetStore

  const {
    cartTickets = [],
    isAdjusting = false,
    isClearing = false
  } = cartStore

  const {
    walletAmount = 0,
    isWalletAmountLoading,
    profile,
    conversation
  } = authStore

  const isLoggedIn = Boolean(profile)

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)

  const [notificationsCount, setNotificationsCount] = useState(0)

  const [readPromotionNotificationsIds, setReadPromotionNotificationsIds] =
    useState<string[] | null>(null)

  const [unreadMessageCount, setUnreadMessageCount] = useState(0)

  const [promotions, setPromotions] = useState<string[] | null>(null)

  const hamburgerMenuRef = useRef(null)

  const headerRef = useRef<HTMLDivElement | null>(null)

  const notificationListener = useRef(null)

  const promotionsListener = useRef(null)

  const storeClientTelemetryDataTimer = useRef<number | null>(null)

  const { data: applicationConfig = null } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_APPLICATION_CONFIG],
    queryFn: () =>
      ApplicationConfigService.getApplicationConfig({
        tenantId: 'default'
      })
  })

  const isDisableBasket = useMemo(() => {
    return isClearing || isAdjusting
  }, [isClearing, isAdjusting])

  const walletMoneyBackPercentage = useMemo(() => {
    if (isNil(applicationConfig)) {
      return 0
    }

    const { walletMoneyBackPercentage = 0 } = applicationConfig

    return walletMoneyBackPercentage
  }, [applicationConfig])

  const showApplicationBanner = useMemo(() => {
    if (isNil(applicationConfig)) {
      return false
    }

    const { showApplicationBanner = false } = applicationConfig

    return showApplicationBanner
  }, [applicationConfig])

  const closeMenu = () => {
    headerRef.current.classList.remove('active')

    document.body.style.overflow = 'auto'
  }

  const openWalletModal = useCallback(() => {
    setIsWalletModalOpen(true)
  }, [])

  const handleCloseBasket = useCallback(() => {
    if (isAdjusting || isClearing) {
      return
    }

    basketWidgetStore.setIsOpen(false)
  }, [basketWidgetStore, isAdjusting, isClearing])

  const onToggleBasket = useCallback(
    event => {
      event.preventDefault()

      basketWidgetStore.setIsOpen(!isOpenBasketWidget)
    },
    [basketWidgetStore, isOpenBasketWidget]
  )

  const unsubscribePromotionsListener = () => {
    if (!isNil(promotionsListener.current)) {
      promotionsListener.current.unsubscribeChangeListener()
      promotionsListener.current.unsubscribeAddListener()
    }
  }

  const unsubscribeNotificationListener = () => {
    if (!isNil(notificationListener.current)) {
      notificationListener.current.unsubscribeChangeListener()
    }
  }

  const unsubscribeListeners = () => {
    unsubscribePromotionsListener()
    unsubscribeNotificationListener()
  }

  const clearTelemetryData = () => {
    if (storeClientTelemetryDataTimer.current) {
      clearTimeout(storeClientTelemetryDataTimer.current)
    }
  }

  const storeClientTelemetryData = async () => {
    try {
      const { clientTelemetryCookieData } = getCookies()

      const checkclientTelemetryApiCalled = localStorageWithTTL.getItem(
        'checkclientTelemetryApiCalled'
      )

      if (clientTelemetryCookieData && !checkclientTelemetryApiCalled) {
        const clientTelemetryData = JSON.parse(clientTelemetryCookieData)
        const isClientDataNotNull =
          Object.values(clientTelemetryData).every(Boolean)
        if (isClientDataNotNull) {
          localStorageWithTTL.setItem(
            'checkclientTelemetryApiCalled',
            true,
            6 * 60 * 60 //6 hours
          )
          await storeUserTelemetry(clientTelemetryData)
        }
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const fetchConversation = async () => {
    const userConversation: ConversationDTO | null =
      await ConversationsService.getMyConversation().catch(() => null)

    authStore.setConversation(userConversation)

    if (!userConversation) {
      setReadPromotionNotificationsIds([])
      setUnreadMessageCount(0)
    }
  }

  const messageNotificationsListener = async () => {
    unsubscribeNotificationListener()

    const { profile } = authStore

    if (isNil(profile)) {
      return
    }

    if (!isNil(conversation)) {
      notificationListener.current = registerMessageNotificationListener(
        conversation.id,
        (userChatData: IUserChatData) => {
          setReadPromotionNotificationsIds(
            get(userChatData, 'read_promotion_ids', [])
          )
          setUnreadMessageCount(get(userChatData, 'unread_message_count', 0))
        }
      )
    } else {
      setReadPromotionNotificationsIds([])
      setUnreadMessageCount(0)
    }
  }

  const subscribePromotionsListener = async () => {
    const { profile } = authStore

    if (isNil(profile)) {
      return
    }

    const promotions = await PromotionsService.getPromotions({})

    setPromotions(promotions.data.map(promotion => promotion.id))

    promotionsListener.current = registerPromotionListener(
      (messageData: IMessageData) => {
        if (isNil(messageData)) {
          return
        }

        const { last_message, type } = messageData

        if (isNil(last_message) || type !== 'promotion') {
          return
        }

        setPromotions(prev => uniq([...prev, last_message.id]))
      }
    )
  }

  useEffect(() => {
    return () => {
      unsubscribeListeners()
      clearTelemetryData()
    }
  }, [])

  useEffect(() => {
    if (profile) {
      storeClientTelemetryDataTimer.current = window.setTimeout(() => {
        storeClientTelemetryData()
      }, 15 * 1000)

      subscribePromotionsListener()
      fetchConversation()
    } else {
      unsubscribePromotionsListener()
      clearTelemetryData()
    }
  }, [profile?.userId])

  useEffect(() => {
    if (conversation) {
      messageNotificationsListener()
    }
  }, [conversation?.id])

  useEffect(() => {
    if (readPromotionNotificationsIds && promotions) {
      const count =
        unreadMessageCount +
        promotions.filter(
          promotion => !readPromotionNotificationsIds.includes(promotion)
        ).length

      setNotificationsCount(count)
      notificationStore.setNotificationCount(count)
    }
  }, [readPromotionNotificationsIds, promotions, unreadMessageCount])

  return (
    <StyledNavbar>
      <Container suppressHydrationWarning={true}>
        <StyledHeader ref={headerRef}>
          <div className="d-flex flex-row justify-content-start align-items-center d-lg-none">
            <StyledNavbarButton
              type="button"
              className="mr-3"
              ref={hamburgerMenuRef}
              onClick={e => {
                e.stopPropagation()
                document.body.style.overflow = 'hidden'
                headerRef.current.classList.toggle('active')
              }}
            >
              <StyledBadge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                badgeContent={notificationsCount}
                showZero={false}
              >
                <HamburgerIcon width="25" height="24" viewBox="0 5 25 24" />
              </StyledBadge>
            </StyledNavbarButton>

            <StyledMobileLogo>
              <Link href="/" prefetch={false}>
                <a>
                  <Image
                    src={getLocalAssetFromCDN('/assets/logos/main-logo.svg')}
                    alt="Elite Competitions Logo"
                    priority={true}
                    width={65}
                    height={24}
                  />
                </a>
              </Link>
            </StyledMobileLogo>
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between">
            <StyledDesktopLogo>
              <Link href="/" prefetch={false}>
                <a>
                  <Image
                    src={getLocalAssetFromCDN('/assets/logos/main-logo.svg')}
                    alt="Elite Competitions Logo"
                    priority={true}
                    width={114}
                    height={42}
                  />
                </a>
              </Link>
            </StyledDesktopLogo>

            <StyledMainMenu
              className="main-menu"
              onClick={e => {
                if (e.currentTarget.tagName === 'NAV') {
                  closeMenu()
                }
              }}
            >
              <StyledMainMenuList className="d-flex align-items-lg-center flex-column flex-lg-row justify-content-start">
                <li className="d-lg-none d-flex justify-content-between align-items-center">
                  <Image
                    src={getLocalAssetFromCDN('/assets/logos/main-logo.svg')}
                    alt="Elite Competitions Logo"
                    priority={true}
                    width={65}
                    height={24}
                  />

                  <StyledNavbarButton type="button" onClick={closeMenu}>
                    <CloseIcon width="32" height="32" viewBox="0 0 32 32" />
                  </StyledNavbarButton>
                </li>

                <StyledScrollContainer>
                  {isLoggedIn && notificationsCount > 0 && (
                    <li className="d-lg-none">
                      <Link href="/my-account/messages" prefetch={false}>
                        <a>
                          <Stack
                            direction="row"
                            width="100%"
                            paddingRight="16px"
                          >
                            <Stack
                              direction="row"
                              width="100%"
                              borderRadius="8px"
                              gap="8px"
                              padding="4px 8px"
                              justifyContent="flex-start"
                              alignItems="center"
                              bgcolor="var(--PrimaryVariant)"
                            >
                              <StyledBadge
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                badgeContent={notificationsCount}
                                showZero={false}
                              >
                                <Typography
                                  fontVariant="body-1"
                                  weight="medium"
                                  color="var(--Neutral1)"
                                >
                                  <i className="fa-light fa-bell" />
                                </Typography>
                              </StyledBadge>

                              <Typography
                                fontVariant="heading-4"
                                weight="medium"
                                color="var(--Neutral1)"
                              >
                                New notification
                              </Typography>
                            </Stack>
                          </Stack>
                        </a>
                      </Link>
                    </li>
                  )}

                  <li className="d-lg-none">
                    <Link href="/" prefetch={false}>
                      <a>Home</a>
                    </Link>
                  </li>

                  {isLoggedIn && (
                    <li>
                      <Link href="/my-account" prefetch={false}>
                        <a>
                          <span className="d-block d-lg-none">My Account</span>

                          <StyledBadge
                            className="d-none d-lg-block"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            badgeContent={notificationsCount}
                            showZero={false}
                          >
                            My Account
                          </StyledBadge>
                        </a>
                      </Link>
                    </li>
                  )}

                  <li>
                    <a className="d-none d-lg-inline-block pointer-none">
                      Competitions <i className="text-white fa fa-angle-down" />
                    </a>

                    <ul>
                      <li>
                        <Link href="/active-competitions" prefetch={false}>
                          <a>Active Competitions</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/instawin-competitions" prefetch={false}>
                          <a>InstaWin Competitions</a>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/category/${CompetitionCategorySlugEnum.CAR_COMPETITIONS}`}
                          prefetch={false}
                        >
                          <a>Car Competitions</a>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/category/${CompetitionCategorySlugEnum.CASH_COMPETITIONS}`}
                          prefetch={false}
                        >
                          <a>Cash Competitions</a>
                        </Link>
                      </li>

                      <li>
                        <Link
                          href={`/category/${CompetitionCategorySlugEnum.WATCH_COMPETITIONS}`}
                          prefetch={false}
                        >
                          <a>Watch Competitions</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/live-draws" prefetch={false}>
                          <a>Live Draws</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/winners-podium" prefetch={false}>
                          <a>Winner Podium</a>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link href="/villa-in-spain" prefetch={false}>
                      <a>
                        <StyledVillaItem>Villa in Spain</StyledVillaItem>
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/work-with-us" prefetch={false}>
                      <a>
                        <StyledWorkWithUsItem>
                          Work with us
                        </StyledWorkWithUsItem>
                      </a>
                    </Link>
                  </li>

                  <li>
                    <a className="d-none d-lg-inline-block pointer-none">
                      More <i className="text-white fa fa-angle-down" />
                    </a>

                    <ul>
                      <li>
                        <Link href="/how-it-works" prefetch={false}>
                          <a>How it works</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/about-us" prefetch={false}>
                          <a>About Us</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/blog" prefetch={false}>
                          <a>Blog</a>
                        </Link>
                      </li>

                      <li>
                        <Link href="/faqs" prefetch={false}>
                          <a>FAQS</a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </StyledScrollContainer>

                {!isLoggedIn && (
                  <StyledMainMenuButtonSection>
                    <Link href="/login" prefetch={false}>
                      <StyledButton size="large" variant="secondary">
                        <span>Log in</span>
                      </StyledButton>
                    </Link>

                    <Link href="/register" prefetch={false}>
                      <StyledButton size="large">
                        <span>Create account</span>
                      </StyledButton>
                    </Link>
                  </StyledMainMenuButtonSection>
                )}
              </StyledMainMenuList>

              {isLoggedIn && (
                <>
                  <StyledNavbarButton
                    type="button"
                    isActive={isWalletModalOpen}
                    className="d-none d-lg-flex flex-row"
                    onClick={openWalletModal}
                  >
                    <WalletIcon width="24" height="24" viewBox="0 0 24 24" />

                    {isWalletAmountLoading ? (
                      <span>
                        Syncing <i className="fa mr-1 fa-spinner fa-spin" />
                      </span>
                    ) : (
                      <span>£{numericWithComma(walletAmount)}</span>
                    )}
                  </StyledNavbarButton>

                  <StyledNavbarButton
                    type="button"
                    isActive={isOpenBasketWidget}
                    isDisabled={isDisableBasket}
                    className="d-none d-lg-flex flex-row cart-nav-link"
                    onClick={onToggleBasket}
                  >
                    <StyledBadge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      max={1000}
                      badgeContent={cartTickets.length}
                    >
                      <CartIcon width="24" height="24" viewBox="-3 0 24 24" />
                    </StyledBadge>
                  </StyledNavbarButton>
                </>
              )}
            </StyledMainMenu>

            {isLoggedIn && (
              <div className="d-flex align-items-center justify-content-end d-lg-none">
                <StyledNavbarButton
                  type="button"
                  isActive={isWalletModalOpen}
                  className="d-flex flex-row mr-3"
                  onClick={openWalletModal}
                >
                  <WalletIcon width="24" height="24" viewBox="0 0 24 24" />

                  {isWalletAmountLoading ? (
                    <span>
                      Syncing <i className="fa mr-1 fa-spinner fa-spin" />
                    </span>
                  ) : (
                    <span className="ml-1">
                      £{numericWithComma(walletAmount)}
                    </span>
                  )}
                </StyledNavbarButton>

                <StyledNavbarButton
                  type="button"
                  className="cart-nav-link"
                  isActive={isOpenBasketWidget}
                  isDisabled={isDisableBasket}
                  onClick={onToggleBasket}
                >
                  <StyledBadge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    max={1000}
                    badgeContent={cartTickets.length}
                  >
                    <CartIcon width="24" height="24" viewBox="-3 0 24 24" />
                  </StyledBadge>
                </StyledNavbarButton>
              </div>
            )}

            {isLoggedIn && (
              <Basket show={isOpenBasketWidget} onClose={handleCloseBasket} />
            )}
          </div>
        </StyledHeader>

        <WalletModal
          isOpen={isWalletModalOpen}
          onClose={() => {
            setIsWalletModalOpen(false)
          }}
          walletMoneyBackPercentage={walletMoneyBackPercentage}
          walletAmount={walletAmount}
          isWalletAmountLoading={isWalletAmountLoading}
        />
      </Container>

      <BannerPromotion showApplicationBanner={showApplicationBanner} />
    </StyledNavbar>
  )
})

export default Navbar
